import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Users from "./Pages/Users";
import UserView from "./Pages/UserView";
import Login from "./Pages/Login";
import { LogOut } from "./Pages/LogOut";
import Devices from "./Pages/Devices";
import HistoryRecords from './Pages/HistoryRecords'
import Groups from "./Pages/Groups";
import Billings from "./Pages/Billings";

import AccountManagements from "./Pages/AccountManagements";
import ServerSettings from "./Pages/ServerSettings";
import AccountSettings from "./Pages/AccountSettings";
import SystemLogs from "./Pages/SystemLogs";
import ActionLogs from "./Pages/ActionLogs";
import RoleManagement from "./Pages/Role";
import Plans from "./Pages/Plans";
import ActivationCode from "./Pages/ActivationCode";
import TemplateGenerator from "./Pages/TemplateGenerator";
import Trash from "./Pages/Trash";
import Coupon from "./Pages/Coupon";
import WebHooks from "./Pages/WebHooks";
import NotFoundPage from "./Pages/NotFound";
import "react-toastify/dist/ReactToastify.css";
import "./assets/main.scss";
import i18n from "./i18n";

import { withTranslation } from "react-i18next";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabling: "",
      callResponse: false,
      isDown: false,
      brandingCallInProcess: false,
    };
  }

  componentWillMount() {
    this.fetchBranding();
  }
  setActiveLanguage = (lng) => {
    localStorage.setItem("SelectedLanguage", lng);
    i18n.changeLanguage(lng);
  };

  fetchBranding = () => {
    fetch(`/api/accounts/labeling?hostUrl=${window.location.host}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              this.setState({
                whiteLabling: res.data,
                callResponse: true,
                brandingCallInProcess: false,
              });
              document.title = res.data.serviceTitle;
              let domElements = document.getElementById("favicon");
              if (res.data && res.data.favIcon && domElements) {
                domElements.setAttribute("href", res.data.favIcon);
              }
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.setState({
                callResponse: true,
              });
            } else {
              this.setState({
                callResponse: true,
                brandingCallInProcess: false,
              });
            }
          });
        } else {
          this.setState({
            callResponse: true,
          });
          console.log("Server is Down");
          throw response;
        }
      })
      .catch((e) => {
        this.setState({
          isDown: true,
          brandingCallInProcess: false,
        });
        console.log(e);
      });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        {this.state.callResponse ? (
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />

            <Route
              path="/login"
              element={
                <Login
                  {...this.state}
                  setActiveLanguage={this.setActiveLanguage}
                  translate={t}
                />
              }
            />

            <Route
              path="/accountSettings"
              element={
                <AccountSettings
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />
            <Route
              path="/users"
              element={
                <Users
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />
            <Route
              path="/users/view"
              element={
                <UserView
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/users/:id"
              element={
                <Users
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/users/edit/:userId"
              element={
                <Users
                  {...this.state}
                  addUserScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/units"
              element={
                <Devices
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />
            <Route
              path="/historyRecords"
              element={
                <HistoryRecords
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/units/:id"
              element={
                <Devices
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/units/create/:userId"
              element={
                <Devices
                  {...this.state}
                  addUnitScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/billings"
              element={
                <Billings
                  {...this.state}
                  addUnitScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/billings"
              element={
                <Billings
                  {...this.state}
                  addUnitScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />
            <Route
              path="/groups"
              element={
                <Groups
                  {...this.state}
                  addUnitScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />
            <Route
              path="/groups/:id"
              element={
                <Groups
                  {...this.state}
                  addUnitScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/accountManagements"
              element={
                <AccountManagements
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/serverSettings"
              element={
                <ServerSettings
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/logout"
              element={
                <LogOut
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/templateGenerator"
              element={
                <TemplateGenerator
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/systemLogs"
              element={
                <SystemLogs
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/actionLogs"
              element={
                <ActionLogs
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/roleManagement"
              element={
                <RoleManagement
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/roleManagement/:id"
              element={
                <RoleManagement
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/trash"
              element={
                <Trash
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/coupon"
              element={
                <Coupon
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/webHooks"
              element={
                <WebHooks
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />
            <Route
              path="/plans"
              element={
                <Plans
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/activationCode"
              element={
                <ActivationCode
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />
            <Route
              path="*"
              element={
                <NotFoundPage
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            {/* <Route
              exact
              path='/groups'
              render={routeProps => <Groups {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/groups/:id'
              render={routeProps => <Groups {...routeProps} {...this.state} />}
            /> */}

            {/* <Route
              path="/triggers"
              element={
                <Triggers
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/schedule-Reports-And-Notifications"
              element={
                <ScheduleReportsAndNotifications
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/accounts"
              element={
                <Account
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/maintenance_dashboard"
              element={
                <MaintenanceDashboard
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/ex_dashboard"
              element={
                <ExDashboard
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/offers"
              element={
                <Offers
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

           

            

            <Route
              path="/b2c"
              element={
                <B2C
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/new_payment"
              element={
                <NewPayment
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/payment-detail"
              element={
                <Payment
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/new_payment/:id"
              element={
                <NewPayment
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/vehicle"
              element={
                <Vehicle
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            


          

            <Route
              path="/maic-integration"
              element={
                <MaicIntegration
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

         

            <Route
              path="/missing-items"
              element={
                <MissingItems
                  {...this.state}
                  addUnitScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/missed-opportunities"
              element={
                <MissedOpportunities
                  {...this.state}
                  addUnitScreen={true}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/accountManagements"
              element={
                <AccountManagements
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/serverSettings"
              element={
                <ServerSettings
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            
            <Route
              path="/logout"
              element={
                <LogOut
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

           

            <Route
              path="/contractManagement"
              element={
                <ContractManagment
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/contractManagement/:id"
              element={
                <ContractManagment
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/invoice"
              element={
                <Invoice
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/invoice/:id"
              element={
                <Invoice
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/invoiceDetail"
              element={
                <InvoiceDetail
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/maintenance-invoice-detail"
              element={
                <MaintenanceInvoiceDetail
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/delivery-note"
              element={
                <DeliveryNote
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/delivery-note/:id"
              element={
                <DeliveryNote
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/delivery-detail"
              element={
                <DeliveryDetail
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

           

        

            <Route
              path="/commutator"
              element={
                <Commutator
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/credit_debit_notes"
              element={
                <CreditDebit
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/workshops"
              element={
                <Workshops
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/reports"
              element={
                <Reports
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/simah"
              element={
                <Simah
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

            <Route
              path="/quotation"
              element={
                <Quotations
                  {...this.state}
                  translate={t}
                  setActiveLanguage={this.setActiveLanguage}
                />
              }
            />

           */}
          </Routes>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(App);
