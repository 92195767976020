import React, { Component } from 'react'
import Layout from './../../wrapper'
import { connect } from 'react-redux'
import withTranslationWrapper from '../../HOC/HocTranslate'
import { notifyError } from '../../Utils/CustomNotifcations'
import { HistoryRecordsList } from '../../Components/HistoryRecords/historyRecordsTable'
import { logInUserInfo } from '../../Actions/Users'

import { checkPrivileges } from '../../Helpers'
import withRoutes from '../../HOC/HocRoutes'

class HistoryRecords extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisable: true,
      sessionCallTrack: true,
      unitsApiCall: true
    }
  }
  componentWillMount () {
        // this.props.dispatch(Notifications.removeAll())
  }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.navigate('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }
  render () {
    if (this.props.logInUser && this.props.logInUser.userType === -1) {
      return (
        <Layout
          {...this.props}
          noSidebar
        >
          <HistoryRecordsList {...this.props} />
        </Layout>
      )
    } else {
      fetch('/api/session?app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312', {
        headers: { Accept: 'application/json; odata=verbose' }
      }).then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){ 
              let userInfo = res.data
              this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
              this.props.dispatch(logInUserInfo(userInfo))
              if (userInfo.userType !== -1) {
                this.props.navigate('/logout')
              }
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                notifyError(err[1])
              )
              this.props.navigate('/logout')
            }
          })
        } else {
          this.props.navigate('/logout')
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(notifyError('somethingWentWrong'))
        })
      return null
    }
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  users: state.users,
  roles: state.roles,
  devices: state.devices
})

export default connect(mapStateToProps)(withRoutes(withTranslationWrapper(HistoryRecords)))

const defaultPages = ['accounts', 'users', 'group']
