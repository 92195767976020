import React, { Component, Fragment } from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { checkPrivileges } from "../../../../Helpers";
import { updateUser, parentUsersLimit } from "../../../../Actions/Users";
import { updateAccount } from "../../../../Actions/Accounts";
import { validEmailPattern } from "../../../../Helpers";
import { parentUnitsLimit } from "../../../../Actions/Devices";
import AddUserModal from "../../addUserModal";
import isEqual from "react-fast-compare";
import MarkerMap from "../../../common/Maps";
import CustomDialog from "../../../common/Dialog";
import { checkUnitsWithStandardType } from "../../userDataTypes";
import { ServerInfo } from "../../../../Actions/serverSetting";
import {
  notifyError,
  notifySuccess,
} from "../../../../Utils/CustomNotifcations";
import withTranslationWrapper from "../../../../HOC/HocTranslate";

const userDataFormat = (data, extAttriubtes, itemId) => {
  return {
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : "",
    deviceLimit: data.deviceLimit ? data.deviceLimit : "",
    deviceReadonly:
      data.deviceReadonly === "on" || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === "on" || data.disabled === true ? true : false,
    email: ("" + data.email).trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: itemId ? itemId : 0,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands:
      data.limitCommands === "on" || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    // map: data.map,
    name: ("" + data.name).trim(),
    map: Array.isArray(data.map) && data.map.length === 0 ? "" : data.map || "",
    // phone: data.phone,
    phone:
      Array.isArray(data.phone) && data.phone.length === 0
        ? ""
        : data.phone || "",
    poiLayer: data.poiLayer,
    readonly: data.readonly === "on" || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === "on" || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit ? data.userLimit : "",
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId || 0,
    userType: parseInt(data.userType),
    parentId: data.parentId || 0,
    // reportInterval: data.reportInterval || 0,
    // historyInterval: data.historyInterval || 0
  };
};

const accountDataFormat = (data, form) => {
  return {
    // attributes: data.attributes ||  {},
    attributes: {
      ...data.attributes,
      firebaseAccount: form.attributes.firebaseAccount
        ? form.attributes.firebaseAccount
        : data.attributes.firebaseAccount || "",
    },
    contact: form.accountContact || data.contact,
    created: form.created,
    description: form.accountDescription || data.description,
    email: form.accountEmail,
    id: data.id || 0,
    modified: form.modified,
    name: form.accountName,
    reportInterval: form.reportInterval || data.reportInterval || 0,
    historyInterval: form.historyInterval || data.historyInterval || 0,
    parentId: data.parentId || 0,
  };
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

class updateUserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      units: "",
      form: {
        ...this.props.selectedUser,
        accountName:
          this.props.selectedAccount?.name ||
          this.props.selectedUser?.extraInfo?.accountName,
        accountEmail:
          this.props.selectedAccount?.email ||
          this.props.selectedUser?.extraInfo?.extraInfo?.accountEmail,
        accountDescription: this.props.selectedAccount?.description || "",
        accountContact: this.props.selectedAccount?.contact || "",
        reportInterval: this.props.selectedAccount?.reportInterval || 0,
        historyInterval: this.props.selectedAccount?.historyInterval || 0,
      },
      selectedUser: this.props.selectedUser,
      isVisableUserBtn: false,
      duplicateEntry: false,
      userLimit: true,
      deviceLimitError: false,
      userLimitError: false,
      unitsLimitsMeaasge: "",
      usersLimitsMeaasge: "",
      currentUserRole: [],
      localPostionObj: "",
      isVisableAddlocBtn: false,
      editOption: true,
      notVisableBackbtn: true,
    };
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.uu_submit = this.uu_submit.bind(this);
    this.generateToken = this.generateToken.bind(this);
    this.timeDateOnchange = this.timeDateOnchange.bind(this);
    this.changeAttribute2 = this.changeAttribute2.bind(this);
  }

  handleChange2 = (name) => (event) => {
    if (!event) {
      // Handle the case where event is null (e.g., when clearing the input)
      this.setState({
        [name]: null,
      });
      return;
    }
    const { target } = event;
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "reportInterval" || name === "historyInterval") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: parseInt(target.value),
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "expirationTime") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "password" || name === "confirmPassword") {
      let value = target.value;
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        () => this.verifyPassword()
      );
    } else {
      let value = target.value;
      if (value) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        if (name === "deviceLimit") {
          if (
            parseInt(value) === -1 &&
            parseInt(this.props.pUnitLimits.remaningLimit) === -1
          ) {
            this.setState(
              {
                deviceLimitError: false,
                unitsLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(value) <
                parseInt(
                  this.props.selectedUnitsLimits.count +
                    this.props.selectedUnitsLimits.reserveLimit
                )
              ) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitError",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                if (parseInt(this.props.pUnitLimits.remaningLimit) === -1) {
                  this.setState(
                    {
                      deviceLimitError: false,
                      unitsLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                } else if (
                  parseInt(value) >
                  parseInt(
                    this.props.pUnitLimits.limit -
                      this.props.pUnitLimits.totalCount
                  )
                ) {
                  this.setState(
                    {
                      deviceLimitError: true,
                      unitsLimitsMeaasge: "deviceLimitErrorParent",
                    },
                    () => this.checkRequiredFields()
                  );
                } else {
                  this.setState(
                    {
                      deviceLimitError: false,
                      unitsLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                }
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "userLimit") {
          if (
            parseInt(value) === -1 &&
            parseInt(this.props.pUserLimits.remaningLimit) === -1
          ) {
            this.setState(
              {
                userLimitError: false,
                usersLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(value) <
                parseInt(
                  this.props.selectedUserLimits.count +
                    this.props.selectedUserLimits.reserveLimit
                )
              ) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitError",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                if (parseInt(this.props.pUserLimits.remaningLimit) === -1) {
                  this.setState(
                    {
                      userLimitError: false,
                      usersLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                } else if (
                  parseInt(value) >
                  parseInt(
                    this.props.pUserLimits.limit -
                      this.props.pUserLimits.totalCount
                  )
                ) {
                  this.setState(
                    {
                      userLimitError: true,
                      usersLimitsMeaasge: "userLimitErrorParent",
                    },
                    () => this.checkRequiredFields()
                  );
                } else {
                  this.setState(
                    {
                      userLimitError: false,
                      usersLimitsMeaasge: "",
                    },
                    () => this.checkRequiredFields()
                  );
                }
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      } else if (this.state.form[name]) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        if (name === "deviceLimit") {
          if (this.props.pUnitLimits.count !== 0) {
            this.setState(
              {
                deviceLimitError: true,
                unitsLimitsMeaasge: "deviceLimitError",
              },
              () => this.checkRequiredFields()
            );
          } else {
            this.setState(
              {
                deviceLimitError: false,
              },
              () => this.checkRequiredFields()
            );
          }
        }
        if (name === "userLimit") {
          if (this.props.selectedUserLimits.count !== 0) {
            this.setState(
              {
                userLimitError: true,
                usersLimitsMeaasge: "userLimitError",
              },
              () => this.checkRequiredFields()
            );
          } else {
            this.setState(
              {
                userLimitError: false,
              },
              () => this.checkRequiredFields()
            );
          }
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };

  // checkRequiredFields () {
  //   let valid = true
  //   let {
  //     name,
  //     email,
  //     password,
  //     confirmPassword,
  //     userType,
  //     accountId,
  //     parentId,
  //     zoom,
  //   } = this.state.form
  //   let {
  //     isValidPassword
  //   } = this.state

  //   if (
  //     name &&
  //     email &&
  //     accountId &&
  //     parentId &&
  //     isValidPassword &&
  //     password &&
  //     confirmPassword &&
  //     password === confirmPassword &&
  //     zoom&&
  //     userType
  //   ) {
  //     if (!validEmailPattern.test(email)) {
  //       valid = false
  //     }
  //     // check both user and device limit error
  //     let limitError = true
  //     if (!this.state.deviceLimitError && !this.state.userLimitError) {
  //       limitError = false
  //     }
  //     if (zoom > 2 && zoom < 19) {
  //       this.setState({
  //         isVisableUserBtn: limitError ? false : valid,
  //         duplicateEntry: !valid
  //       })
  //     } else {
  //       this.setState({
  //         isVisableUserBtn: false,
  //         duplicateEntry: false
  //       })
  //     }
  //   } else {
  //     this.setState({
  //       isVisableUserBtn: false,
  //       duplicateEntry: false
  //     })
  //   }
  // }

  verifyPassword = () => {
    // Define regular expressions for password criteria
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check each criteria
    const isLengthValid = this.state.form.password.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(this.state.form.password);
    const hasUpperCaseValid = hasUpperCase.test(this.state.form.password);
    const hasNumberValid = hasNumber.test(this.state.form.password);
    const hasSpecialCharValid = hasSpecialChar.test(this.state.form.password);

    // Check if all criteria are met

    const isPasswordValid =
      isLengthValid &&
      hasLowerCaseValid &&
      hasUpperCaseValid &&
      hasNumberValid &&
      hasSpecialCharValid;

    this.setState(
      {
        isValidPassword: isPasswordValid,
      },
      () => this.checkRequiredFields()
    );
  };
  checkRequiredFields() {
    let valid = true;
    let { name, email, roleId, userType, zoom } = this.state.form;
    let value = !isEqual(this.state.form, this.props.selectedUser);
    if (name && email && userType && roleId && value && zoom) {
      if (!validEmailPattern.test(email)) {
        valid = false;
      }
      // check both user and device limit error
      let limitError = true;
      if (!this.state.deviceLimitError && !this.state.userLimitError) {
        limitError = false;
      }
      if (zoom > 2 && zoom < 19) {
        this.setState({
          isVisableUserBtn: limitError ? false : valid,
          duplicateEntry: !valid,
        });
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
      // this.setState(
      //   {
      //     isVisableUserBtn: limitError ? false : valid,
      //     duplicateEntry: !valid
      //   })
    } else {
      this.setState({
        isVisableUserBtn: false,
        duplicateEntry: false,
      });
    }
  }

  changeAttribute2(name, event) {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (!value) {
      let a = { ...this.state.form.attributes };
      delete a[name];
      if (!Object.keys(a).length) {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes:
                this.props.selectedUser.attributes === null ? null : {},
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...a,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      if (name === "parking.minIdleTime") {
        value = parseFloat(value);
      }
      if (name === "parking.maxIdleSpeed") {
        value = parseFloat(value);
      }
      if (name === "measurementStandard") {
        let data = checkUnitsWithStandardType(value);
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]:
                  event.target.type === "number" ? parseInt(value) : value,
                distanceUnit: data[0].distanceUnit.key,
                speedUnit: data[0].speedUnit.key,
                volumeUnit: data[0].volumeUnit.key,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: name === "mailLimit" ? parseInt(value) : value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  }
  timezone_handleChange = (name, value) => {
    if (!value || value.value === "None") {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name];
      }
      let a = { ...this.state.form.attributes };
      delete a[name];
      if (!Object.keys(a).length) {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes:
                this.props.selectedUser.attributes === null ? null : {},
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value.value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  uu_submit() {
    const { selectedUser } = this.state;
    let { name, email, attributes } = this.state.form;
    if (("" + name).trim().length > 0 && ("" + email).trim().length > 0) {
      let itemId = selectedUser.id;

      const obj = userDataFormat(this.state.form, attributes, itemId);
      const AccountObj = accountDataFormat(
        this.props.selectedAccount,
        this.state.form
      );

      if (this.state.form.userType > 2) {
        fetch(`/api/accounts/${this.props.selectedAccount.id}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...AccountObj,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                let account = res.data;
                if (res.status === "success") {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        accountName: AccountObj?.name || "",
                        accountEmail: AccountObj?.email || "",
                        accountDescription: AccountObj?.description || "",
                        accountContact: AccountObj?.contact || "",
                        reportInterval: AccountObj?.reportInterval || 0,
                        historyInterval: AccountObj?.historyInterval || 0,
                      },
                    },
                    () => {
                      this.props.updateSelectedAccount(account);
                    }
                  );
                  this.props.dispatch(updateAccount(account));
                  // form: { ...this.props.selectedUser, accountEmail:this.props.selectedUser&&this.props.selectedUser.extraInfo&&this.props.selectedUser.extraInfo.accountEmail },
                  if (this.props.selectedAccount.id === 1) {
                    this.props.dispatch(ServerInfo(account));
                  }
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(
                    notifyError(this.props.translate(err[1]))
                  );
                }
              });
            }
          })
          .catch((e) => {
            this.props.dispatch(
              notifyError(this.props.translate("somethingWentWrong"))
            );
          });
      }
      fetch(`/api/users/${itemId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...obj,
        }),
      })
        .then((response) => {
          response.json().then((res1) => {
            if (res1.status === "success") {
              let user = res1.data;
              let parent = {
                parentId: this.props.logInUser.id,
                parentName: this.props.logInUser.name,
              };
              user = { ...user, ...parent };
              this.props.dispatch(updateUser(user));
              this.props.UpdateSelectedItem(user);
              if (
                parseInt(this.props.selectedUser.parentId) ===
                parseInt(this.props.logInUser.id)
              ) {
                this.getUsersLimits();
              }
              this.props.dispatch(
                notifySuccess(this.props.translate("userIsUpdated"))
              );
              this.setState({
                // form: { ...user},
                form: {
                  ...user,
                  accountName: AccountObj?.name || "",
                  accountEmail: AccountObj?.email || "",
                  accountDescription: AccountObj?.description || "",
                  accountContact: AccountObj?.contact || "",
                  reportInterval: AccountObj?.reportInterval || 0,
                  historyInterval: AccountObj?.historyInterval || 0,
                },
                selectedUser: user,
                isVisableUserBtn: false,
              });
            } else if (res1?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res1.statusCode) {
              var err = res1?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(notifyError(this.props.translate(err[1])));
            }
          });
        })
        .catch((e) => {
          this.props.dispatch(notifyError("somethingWentWrong"));
        });
    } else {
      this.setState({
        isVisableUserBtn: false,
      });
      this.props.dispatch(notifyError(this.props.translate("emptyField")));
    }
  }

  getUsersLimits = () => {
    if (this.props.logInUser.id) {
      //units limits
      if (checkPrivileges("device")) {
        fetch(`/api/devices/count?userId=${this.props.logInUser.id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  this.props.dispatch(parentUnitsLimit(res.data));
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(
                    notifyError(this.props.translate(err[1]))
                  );
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {});
      }
      //users limits
      if (checkPrivileges("user")) {
        fetch(`/api/users/count?userId=${this.props.logInUser.id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  this.props.dispatch(parentUsersLimit(res.data));
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(
                    notifyError(this.props.translate(err[1]))
                  );
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {});
      }
    }
  };
  generateToken() {
    let symbols =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i,
      newToken = "";
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken,
        },
      },
      () => this.checkRequiredFields()
    );
  }
  copyTokenToClipboard = () => {
    const { form } = this.state;
    const token = form.token;

    const hostUrl = this.props.ServerSetting?.attributes?.hostUrl || "";
    const tokenUrl = `${hostUrl}/loginasuser?&${btoa(token)}`;

    // Create a temporary textarea element to copy the token to clipboard
    const tempTextarea = document.createElement("textarea");

    tempTextarea.value = tokenUrl;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);

    // Show a notification indicating that the token has been copied to clipboard
    notifySuccess("Token copied to clipboard");
  };
  timeDateOnchange(date) {
    this.setState((preState) => ({
      ...preState,
      form: {
        ...preState.form,
        timeDate: date,
      },
    }));
  }
  setExpirationTime = (e) => {
    e.stopPropagation();
    if (this.state.form.expirationTime) {
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false,
    });
  };
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true,
      });
    }
  };
  setNoToken = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          token: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  setSelectedLocation = () => {
    const { localPostionObj } = this.state;
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom,
          },
          isMapModal: false,
        },
        () => this.checkRequiredFields()
      );
    }
  };
  onCloseModal = () => {
    this.setState({
      isMapModal: false,
    });
  };
  render() {
    return (
      <Fragment>
        <AddUserModal
          {...this.state}
          {...this.props}
          logInUser={this.props.logInUser}
          padding={"0px"}
          elevation={0}
          formSubmit={this.uu_submit}
          timeDateOnchange={this.timeDateOnchange}
          generateToken={this.generateToken}
          copyTokenToClipboard={this.copyTokenToClipboard}
          changeAttribute2={this.changeAttribute2}
          handleChange={this.handleChange2}
          buttonText={this.props.translate("update")}
          handleClickShowPostion={this.handleClickShowPostion}
          userType={this.props.logInUser.userType}
          setExpirationTime={this.setExpirationTime}
          setNoToken={this.setNoToken}
          roleTypes={this.props.roles}
          timezone_handleChange={this.timezone_handleChange}
          hasAccessOfUpdate={checkPrivileges("userUpdate")}
          hasAccessOfDelete={checkPrivileges("userDelete")}
          hasAccessOfCreate={checkPrivileges("userCreate")}
          usersLimit={
            this.props.pUserLimits.limit - this.props.pUserLimits.totalCount
          }
          deviceLimit={
            this.props.pUnitLimits.limit - this.props.pUnitLimits.totalCount
          }
          createdUnits={this.props.selectedUnitsLimits.unitCount}
          createdUsers={this.props.selectedUnitsLimits.totalCount}
        />
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate("setLocation")}
            themeColors={this.props.themeColors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            fullWidth
            CancelText={this.props.translate("sharedBack")}
            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {" "}
                {this.props.translate("sharedAdd")}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.selectedUser.zoom || 0}
              lat={this.state.selectedUser.latitude || 0}
              lng={this.state.selectedUser.longitude || 0}
              height={"100%"}
            />
          </CustomDialog>
        )}
      </Fragment>
    );
  }
}

const UpdateUserDetail = withStyles(styles);
export default UpdateUserDetail(withTranslationWrapper(updateUserDetail));
